<template>
    <div class="alert-box" v-if="showError" style="">
        <div class="alert-box-img"></div>
        <div class="alert-box-close hover:bg-[#fb8900] text-center mt-2 mb-3 py-2" @click="closePopup">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="18px" height="18px" viewBox="0 0 121.31 122.876" enable-background="new 0 0 121.31 122.876" xml:space="preserve" fill="#ffffff"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M90.914,5.296c6.927-7.034,18.188-7.065,25.154-0.068 c6.961,6.995,6.991,18.369,0.068,25.397L85.743,61.452l30.425,30.855c6.866,6.978,6.773,18.28-0.208,25.247 c-6.983,6.964-18.21,6.946-25.074-0.031L60.669,86.881L30.395,117.58c-6.927,7.034-18.188,7.065-25.154,0.068 c-6.961-6.995-6.992-18.369-0.068-25.397l30.393-30.827L5.142,30.568c-6.867-6.978-6.773-18.28,0.208-25.247 c6.983-6.963,18.21-6.946,25.074,0.031l30.217,30.643L90.914,5.296L90.914,5.296z"/></g></svg>
        </div>
        <div class="alert-box-body">
            <div class="alert-box-heading text-center">{{ errorHeader}}</div>
            <div class="alert-box-subheading text-center">{{ errorMessage }}</div>
            <div class="button">
                <div class="alert-box-ok bg-[#FFAC48] hover:bg-[#fb8900] text-center mt-2 mb-3 py-2" style="" @click="reloadPage">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="23px" height="23px" viewBox="0 0 96 96">
                        <title/>
                        <g>
                            <path d="M94.2422,37.7578a5.9979,5.9979,0,0,0-8.4844,0l-2.61,2.61A36.0347,36.0347,0,0,0,48,12a35.55,35.55,0,0,0-21.6211,7.3594,5.9977,5.9977,0,0,0,7.2422,9.5625A23.6677,23.6677,0,0,1,48,24,23.957,23.957,0,0,1,70.6729,40.4766l-3.97-3.1641a5.9956,5.9956,0,1,0-7.4765,9.375l15.0351,12a5.99,5.99,0,0,0,7.98-.4453l12-12A5.9979,5.9979,0,0,0,94.2422,37.7578Z"/>
                            <path d="M62.3789,67.0781A23.6675,23.6675,0,0,1,48,72,23.957,23.957,0,0,1,25.3271,55.5234l3.97,3.1641a5.9956,5.9956,0,1,0,7.4765-9.375l-15.0351-12a6.0071,6.0071,0,0,0-7.98.4453l-12,12a5.9994,5.9994,0,0,0,8.4844,8.4844l2.61-2.61A36.0347,36.0347,0,0,0,48,84a35.55,35.55,0,0,0,21.6211-7.3594,5.9977,5.9977,0,1,0-7.2422-9.5625Z"/>
                        </g>
                    </svg>
                </div>
                <span class="text">Reload</span>
            </div>
        </div>
    </div>



</template>

<script>
export default {
    props: {
        errorMessage: String,
        errorHeader: String,
    },
    data() {
        return {
            showError: false,
        };
    },
    mounted() {
        // Add event listener to detect clicks outside the popup
        document.addEventListener("click", this.handleClickOutside);
    },
    beforeUnmount() {
        // Remove event listener when component is unmounted
        document.removeEventListener("click", this.handleClickOutside);
    },
    methods: {
        closePopup() {
            this.showError = false;
        },
        reloadPage() {
            window.location.reload();
        },
        handleClickOutside(event) {
            // Close the popup if clicked outside
            if (!this.$el.contains(event.target)) {
                this.closePopup();
            }
        },
    },
    watch: {
        errorMessage(newValue) {
            if (newValue) {
                this.showError = true;
            }
        }
    }
}
</script>

<style scoped>

.alert-box {
    width: 464px;
    height: 285px;
    box-shadow: 0 30px 60px 0 #0000004d;
    background: #fff;
    border-radius: 10px;
    margin: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.alert-box-img {
    border-radius: 10px 10px 0 0;
    height: 115px;
    background: #065F51 url('/images/error_header_image.png') no-repeat center;
    background-size: cover;
}

.alert-box-body {
    background: linear-gradient(#fff, #cfcfcf);
    height: 170px;
    border-radius: 0 0 10px 10px;
}
.alert-box-heading {
    line-height: 50px;
}
.alert-box-heading, .alert-box-subheading {
    color: #0c2e60;
    font-size: 18px;
    display: block;
}
.text-center {
    text-align: center;
}

.alert-box-subheading {
    line-height: 26px;
    font-size: 15px;
    letter-spacing: 1px;
    color: #666;
}
.alert-box-ok {

    border-radius: 22px;
    width: 132px;
    height: 38px;
    color: #fff;
    cursor: pointer;
    margin: 10px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.alert-box-close {

    border-radius: 22px;
    width: 132px;
    height: 38px;
    color: #fff;
    cursor: pointer;
    margin: 10px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 0px;
}
.button{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.text{
    margin-top: -10px;
}
</style>
